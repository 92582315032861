import React from "react";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import AdminMenu from "../../components/Layout/AdminMenu";
import { FaUser, FaEnvelope, FaPhone } from "react-icons/fa"; // Importing icons
import "./../../styles/AdminDashboard.css";

const AdminDashboard = () => {
  const [auth] = useAuth();
  return (
    <Layout>
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-8">
            <div className="card w-75 p-3">
              <h3>
                <FaUser className="icon" /> Admin Name : {auth?.user?.name}
              </h3>
              <h3>
                <FaEnvelope className="icon" /> Admin Email :{" "}
                {auth?.user?.email}
              </h3>
              <h3>
                <FaPhone className="icon" /> Admin Contact : {auth?.user?.phone}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
