import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import Layout from "../components/Layout/Layout";
import "./../styles/Blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await axios.get(
          `${API_BASE_URL}/api/v1/blogs/get-blogs`
        );
        setBlogs(data.blogs);
      } catch (error) {
        console.error("Error fetching blogs", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <Layout title="Blogs">
      <div className="container mt-3">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <div className="loading-text">Loading Blogs, please wait...</div>
          </div>
        ) : (
          <div className="row">
            {blogs.map((blog) => (
              <div className="col-md-4" key={blog._id}>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5
                      className="blog-title"
                      dangerouslySetInnerHTML={{ __html: blog.title }}
                    ></h5>
                    <div
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: blog.content.substring(0, 200) + "...",
                      }}
                    ></div>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(`/blogs/${blog.slug}`)}
                    >
                      Show More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;
