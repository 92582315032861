import React from "react";
import { Link } from "react-router-dom";
import useCategory from "../hooks/useCategory";
import Layout from "../components/Layout/Layout";
import "../styles/Categories.css";

const Categories = () => {
  const categories = useCategory();
  return (
    <Layout title={"All Categories"}>
      <div className="container">
        {/* <div className="title-container">
          <h2 className="category-title">CHOOSE A CATEGORY</h2>
        </div> */}
        <div className="row">
          {categories.map((c) => (
            <div className="col-12 col-sm-6 col-md-4 mt-5 mb-3" key={c._id}>
              <div className="card">
                <Link to={`/category/${c.slug}`} className="btn button-54">
                  {c.name}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
