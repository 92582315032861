import React from "react";
import Layout from "./../components/Layout/Layout";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <Layout title={"Privacy Policy - LikesLelo"}>
      <div className="row policy">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src="https://res.cloudinary.com/dp4jdqxsk/image/upload/f_auto,q_auto/hphhcjmhiefktapk8fux"
            alt="contactus"
            className="policy-image"
          />
        </div>
        <div className="col-md-6 montserrat-font">
          <h3 className="bg-dark p-1 text-white text-center ">
            PRIVACY POLICY
          </h3>
          <p className="text-black">
            At Likes-Lelo, we value your privacy. This Privacy Policy outlines
            how we collect, use, and safeguard your personal information when
            you visit <Link to="/">our website</Link>.
          </p>
          <ul style={{ listStyleType: "square" }}>
            <li>
              We collect personal information like your name and email when you
              voluntarily submit it. Your information helps us improve our
              services, process transactions, and send marketing communications.
            </li>
            <li>
              We use cookies to enhance your website experience. Our services
              are not intended for those under 18.
            </li>
            <li>
              We may update this policy; please review it periodically. If you
              have questions, contact us <Link to="/contact">here</Link>. Last
              updated: 03-06-2024.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Policy;
