import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { API_BASE_URL } from "../config";
import "./../styles/BlogDetail.css";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("BlogDetail component mounted");
    console.log("Blog slug:", slug);

    const fetchBlog = async () => {
      try {
        console.log(
          `Fetching blog from ${API_BASE_URL}/api/v1/blogs/get-blog/${slug}`
        );
        const { data } = await axios.get(
          `${API_BASE_URL}/api/v1/blogs/get-blog/${slug}`
        );
        console.log("API Response:", data);
        if (data.success) {
          setBlog(data.blog);
          console.log("Fetched Blog:", data.blog);
        } else {
          console.error("Error fetching blog", data.message);
        }
      } catch (error) {
        console.error("Error fetching blog", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  return (
    <Layout title={blog ? blog.title : "Loading..."}>
      <div className="container mt-3">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <div className="loading-text">Loading, please wait...</div>
          </div>
        ) : blog ? (
          <div className="blog-detail">
            <h3
              className="blog-title"
              dangerouslySetInnerHTML={{ __html: blog.title }}
            ></h3>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            ></div>
          </div>
        ) : (
          <p className="text-center">Blog not found</p>
        )}
      </div>
    </Layout>
  );
};

export default BlogDetail;
