import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import AdminMenu from "../../components/Layout/AdminMenu";
import { API_BASE_URL } from "../../config";
import "./../../styles/CreateBlog.css";

const CustomToolbar = ({ toolbarId }) => (
  <div id={toolbarId}>
    <select className="ql-font">
      <option value="arial">Arial</option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
      <option value="times-new-roman">Times New Roman</option>
      <option value="verdana">Verdana</option>
      <option value="tahoma">Tahoma</option>
      <option value="trebuchet-ms">Trebuchet MS</option>
      <option value="gill-sans">Gill Sans</option>
      <option value="impact">Impact</option>
      <option value="arial-black">Arial Black</option>
      <option value="palatino">Palatino</option>
      <option value="garamond">Garamond</option>
      <option value="bookman">Bookman</option>
      <option value="comic-sans-ms">Comic Sans MS</option>
      <option value="candara">Candara</option>
      <option value="consolas">Consolas</option>
      <option value="playfair-display">Playfair Display</option>
    </select>
    <select className="ql-size">
      <option value="9px">9px</option>
      <option value="10px">10px</option>
      <option value="11px">11px</option>
      <option value="12px">12px</option>
      <option value="14px">14px</option>
      <option value="16px">16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
      <option value="22px">22px</option>
      <option value="24px">24px</option>
      <option value="26px">26px</option>
      <option value="28px">28px</option>
    </select>
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="4">Heading 4</option>
      <option value="5">Heading 5</option>
      <option value="6">Heading 6</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-blockquote"></button>
    <button className="ql-indent" value="-1"></button>
    <button className="ql-indent" value="+1"></button>
    <select className="ql-align">
      <option value=""></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </select>
    <select className="ql-color"></select>
    <select className="ql-background"></select>
    <button className="ql-link"></button>
    <button className="ql-script" value="sub"></button>
    <button className="ql-script" value="super"></button>
    <button className="ql-code-block"></button>
    <button className="ql-clean"></button>
  </div>
);

const ManageBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [showMore, setShowMore] = useState({});

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await axios.get(
          `${API_BASE_URL}/api/v1/blogs/get-blogs`
        );
        setBlogs(data.blogs);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch blogs");
      }
    };

    fetchBlogs();
  }, []);

  const handleEdit = (blog) => {
    setSelectedBlog(blog);
    setTitle(blog.title);
    setContent(blog.content);
  };

  const handleDelete = async (blogId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/v1/blogs/${blogId}`);
      setBlogs(blogs.filter((blog) => blog._id !== blogId));
      toast.success("Blog deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete blog");
    }
  };

  const handleSave = async () => {
    try {
      const { data } = await axios.put(
        `${API_BASE_URL}/api/v1/blogs/${selectedBlog._id}`,
        {
          title,
          content,
        }
      );
      if (data.success) {
        setBlogs(
          blogs.map((blog) =>
            blog._id === selectedBlog._id ? data.blog : blog
          )
        );
        toast.success("Blog updated successfully");
        setSelectedBlog(null);
        setTitle("");
        setContent("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update blog");
    }
  };

  const truncateContent = (content) => {
    const words = content.split(" ");
    return words.length > 40 ? words.slice(0, 25).join(" ") + "..." : content;
  };

  const handleViewMore = (blogId) => {
    setShowMore((prev) => ({
      ...prev,
      [blogId]: !prev[blogId],
    }));
  };

  return (
    <Layout title="Dashboard - Manage Blogs">
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Manage Blogs</h1>
            <div className="mb-3">
              {blogs.map((blog) => (
                <div key={blog._id} className="mb-2">
                  <h2
                    className="blog-title"
                    dangerouslySetInnerHTML={{ __html: blog.title }}
                  ></h2>
                  <div>
                    {showMore[blog._id] ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                      ></div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateContent(blog.content),
                        }}
                      ></div>
                    )}
                  </div>
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => handleEdit(blog)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger me-2"
                    onClick={() => handleDelete(blog._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleViewMore(blog._id)}
                  >
                    {showMore[blog._id] ? "View Less" : "View More"}
                  </button>
                </div>
              ))}
            </div>
            {selectedBlog && (
              <div>
                <h2>Edit Blog</h2>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <CustomToolbar toolbarId="toolbar-title" />
                  <ReactQuill
                    value={title}
                    onChange={(value) => setTitle(value)}
                    modules={{ toolbar: { container: "#toolbar-title" } }}
                    formats={ManageBlog.formats}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="content">Content</label>
                  <CustomToolbar toolbarId="toolbar-content" />
                  <ReactQuill
                    value={content}
                    onChange={(value) => setContent(value)}
                    modules={{ toolbar: { container: "#toolbar-content" } }}
                    formats={ManageBlog.formats}
                  />
                </div>
                <button className="btn btn-primary me-2" onClick={handleSave}>
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setSelectedBlog(null);
                    setTitle("");
                    setContent("");
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

// ReactQuill modules and formats configuration
ManageBlog.modules = {
  toolbar: {
    container: "#toolbar",
  },
};

ManageBlog.formats = [
  "font",
  "size",
  "header",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "indent",
  "align",
  "color",
  "background",
  "link",
  "script",
  "code-block",
];

// Ensure the font whitelist contains only the values
const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
  "times-new-roman",
  "verdana",
  "tahoma",
  "trebuchet-ms",
  "gill-sans",
  "impact",
  "arial-black",
  "palatino",
  "garamond",
  "bookman",
  "comic-sans-ms",
  "candara",
  "consolas",
  "playfair-display",
];
ReactQuill.Quill.register(Font, true);

const Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
ReactQuill.Quill.register(Size, true);

export default ManageBlog;
